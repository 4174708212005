// Dictionary which relates node name and real name

export const qr_correlations = {
    'A' : 'Portaria',
    'C' : 'A0',
    'D' : 'A4',
    'I' : 'A14',
    'N' : 'ET6',
    'P' : 'ET1',
    'AD' : 'CAM',
    'Q' : 'Biblioteca Lado Esq',
    'Y' : 'Biblioteca Lado Dir',
    'X' : 'Biblioteca',
    'T' : 'Escada Portaria',
    'AH' : 'Lanchonete',
    'AX' : 'Passarela Térreo',
    'AW' : 'PET Mecânica',
    'BA' : 'Secretaria PMR',
    'DC' : 'MT15',
    'DF' : 'MT05',
    'CT' : 'MT19',
    'CB' : 'Laboratório de Mecânica dos Fluidos',
    'BQ' : 'TT09',
    'BC' : 'TT15',
    'EX' : 'MC02',
    'EW' : 'Secretaria Marinha',
    'EH' : 'ES41',
    'FS' : 'ES42',
    'GE' : 'Passarela Superior',
    'HA' : 'Secretaria PMR Superior',
    'GG' : 'MS27',
    'GS' : 'MS01',
    'HE' : 'MZ02',
    'HN' : 'MZ16',
    'HZ' : 'TS27',
    'IG' : 'TS17',
    'IP' : 'TS02'
};



// dictionary relating node name with real name, as well as the floor in which is located

export const correlations = {
    'A0': ['C', 0],
    'A1A': ['C', 0],
    'A2': ['C', 0],
    'A3': ['D', 0],
    'A4': ['D', 0],
    'A5': ['E', 0],
    'A6': ['E', 0],
    'A7': ['F', 0],
    'A8': ['F', 0],
    'A9': ['G', 0],
    'A10': ['G', 0],
    'A10A': ['G', 0],
    'A11': ['H', 0],
    'A12': ['H', 0],
    'A13': ['I', 0],
    'A14A': ['I', 0],
    'A14B': ['J', 0],
    'A15': ['K', 0],
    'A16': ['K', 0],
    'A17': ['L', 0],
    'A18': ['L', 0],
    'A19': ['L', 0],
    'ET1': ['P', 0],
    'ET2': ['O', 0],
    'ET3': ['AC', 0],
    'ET4': ['M', 0],
    'ET5': ['M', 0],
    'ET6': ['N', 0],
    'ET7': ['AC', 0],
    'ET8': ['AD', 0],
    'ET9': ['AE', 0],
    'ET10': ['AF', 0],
    'ET11': ['AG', 0],
    'ET12': ['AH', 0],
    'ET13': ['AB', 0],
    'TT01': ['BK', 0],
    'TT02': ['BJ', 0],
    'TT03': ['BL', 0],
    'TT04': ['BJ', 0],
    'TT05': ['BM', 0],
    'TT06': ['BI', 0],
    'TT07': ['BM', 0],
    'TT08': ['BI', 0],
    'TT09': ['BQ', 0],
    'TT10': ['BH', 0],
    'TT11': ['BO', 0],
    'TT12': ['BH', 0],
    'TT13': ['BP', 0],
    'TT14': ['BF', 0],
    'TT15': ['BC', 0],
    'TT16': ['BF', 0],
    'TT22': ['BB', 0],
    'TT28': ['BT', 0],
    'TT32': ['BV', 0],
    'TT34': ['BX', 0],
    'TT36': ['BZ', 0],
    'HT01': ['CB', 0],
    'HT02': ['CB', 0],
    'HT04': ['AW', 0],
    'MT01': ['DI', 0],
    'MT03': ['DF', 0],
    'MT04': ['CM', 0],
    'MT05': ['DF', 0],
    'MT07': ['DE', 0],
    'MT08': ['CK', 0],
    'MT09': ['DE', 0],
    'MT11': ['DC', 0],
    'MT15': ['DC', 0],
    'MT16': ['CK', 0],
    'MT19': ['CT', 0],
    'MT20': ['DA', 0],
    'MT21': ['CU', 0],
    'MT22': ['DA', 0],
    'MT23': ['CU', 0],
    'MT24': ['CZ', 0],
    'MT25': ['CV', 0],
    'MT26': ['CY', 0],
    'MT28': ['CX', 0],
    'MT30': ['CW', 0],
    'BAT02': ['CH', 0],
    'ES01': ['DM', 1],
    'ES02': ['EY', 1],
    'ES03': ['DN', 1],
    'ES04': ['EZ', 1],
    'ES05': ['DO', 1],
    'ES06': ['FA', 1],
    'ES07': ['DP', 1],
    'ES08': ['FB', 1],
    'ES09': ['DQ', 1],
    'ES10': ['FC', 1],
    'ES11': ['DR', 1],
    'ES12': ['FD', 1],
    'ES13': ['DS', 1],
    'ES14': ['FE', 1],
    'ES15': ['DT', 1],
    'ES16': ['FF', 1],
    'ES17': ['DU', 1],
    'ES18': ['FG', 1],
    'ES19': ['DV', 1],
    'ES20': ['FH', 1],
    'ES21': ['DW', 1],
    'ES22': ['FI', 1],
    'ES23': ['DX', 1],
    'ES24': ['FJ', 1],
    'ES25': ['DY', 1],
    'ES26': ['FK', 1],
    'ES27': ['DZ', 1],
    'ES28': ['FL', 1],
    'ES29': ['EA', 1],
    'ES30': ['FM', 1],
    'ES31': ['EB', 1],
    'ES32': ['FN', 1],
    'ES33': ['EC', 1],
    'ES34': ['FO', 1],
    'ES35': ['ED', 1],
    'ES36': ['FP', 1],
    'ES37': ['EE', 1],
    'ES38': ['FQ', 1],
    'ES39': ['EF', 1],
    'ES40': ['FR', 1],
    'ES41': ['EH', 1],
    'ES42': ['FS', 1],
    'ES43': ['EI', 1],
    'ES44': ['FT', 1],
    'ES45': ['EJ', 1],
    'ES46': ['FU', 1],
    'ES47': ['EK', 1],
    'ES48': ['FV', 1],
    'ES49': ['EL', 1],
    'ES50': ['FW', 1],
    'ES51': ['EM', 1],
    'ES52': ['FX', 1],
    'ES53': ['EN', 1],
    'ES54': ['FZ', 1],
    'ES55': ['EO', 1],
    'ES56': ['FZ', 1],
    'ES57': ['ER', 1],
    'ES58': ['GA', 1],
    'ES59': ['ES', 1],
    'ES60': ['GB', 1],
    'ES61': ['ET', 1],
    'ES62': ['GC', 1],
    'ES63': ['EU', 1],
    'ES64': ['GD', 1],
    'ES65': ['EV', 1],
    'ES66': ['GD', 1],
    'ES67': ['EV', 1],
    'ES68': ['FZ', 1],
    'ES69': ['EV', 1],
    'ES70': ['GC', 1],
    'ES71': ['EU', 1],
    'ES72': ['GB', 1],
    'MC01': ['DR', 1],
    'MC02': ['EX', 1],
    'MC03': ['EX', 1],
    'MC04': ['FL', 1],
    'MC05': ['DW', 1],
    'MS01': ['GS', 1],
    'MS02': ['HO', 1],
    'MS03': ['GR', 1],
    'MS04': ['HN', 1],
    'MS05': ['GQ', 1],
    'MS06': ['HN', 1],
    'MS07': ['GQ', 1],
    'MS08': ['HM', 1],
    'MS09': ['GQ', 1],
    'MS10': ['HM', 1],
    'MS11': ['GP', 1],
    'MS12': ['HL', 1],
    'MS13': ['GN', 1],
    'MS14': ['HK', 1],
    'MS15': ['GM', 1],
    'MS16': ['HJ', 1],
    'MS17': ['GL', 1],
    'MS18': ['HI', 1],
    'MS19': ['GK', 1],
    'MS20': ['HH', 1],
    'MS21': ['GJ', 1],
    'MS22': ['HH', 1],
    'MS23': ['GI', 1],
    'MS24': ['HG', 1],
    'MS25': ['GH', 1],
    'MS26': ['HF', 1],
    'MS27': ['GG', 1],
    'MS28': ['HF', 1],
    'MS29': ['GY', 1],
    'MS30': ['HE', 1],
    'MS31': ['GZ', 1],
    'MS32': ['HE', 1],
    'MS34': ['HD', 1],
    'MZ01': ['GJ', 1],
    'MZ02': ['HE', 1],
    'MZ04': ['HF', 1],
    'MZ06': ['HG', 1],
    'MZ08': ['HI', 1],
    'MZ10': ['HJ', 1],
    'MZ12': ['HK', 1],
    'MZ14': ['HM', 1],
    'MZ16': ['HN', 1],
    'MZ18': ['HO', 1],
    'TS01': ['IN', 1],
    'TS02': ['IP', 1],
    'TS03': ['IM', 1],
    'TS04': ['IP', 1],
    'TS05': ['IL', 1],
    'TS06': ['IQ', 1],
    'TS07': ['IL', 1],
    'TS08': ['IQ', 1],
    'TS09': ['IK', 1],
    'TS10': ['IS', 1],
    'TS11': ['IJ', 1],
    'TS12': ['IT', 1],
    'TS13': ['II', 1],
    'TS14': ['IT', 1],
    'TS15': ['IH', 1],
    'TS16': ['IU', 1],
    'TS17': ['IG', 1],
    'TS18': ['IU', 1],
    'TS19': ['ID', 1],
    'TS20': ['IV', 1],
    'TS21': ['IC', 1],
    'TS23': ['IB', 1],
    'TS25': ['IA', 1],
    'TS27': ['HZ', 1],
    'TS29': ['HY', 1],
    'TS31': ['HY', 1],
    'TS33': ['HX', 1],
    'TS35': ['HW', 1],
    'TS37': ['HV', 1],
    'TS39': ['HU', 1],
    'TS41': ['HR', 1],
    'NDF': ['HT', 1],
    'TPN': ['DL', 0],
    'TS43': ['HQ', 1],
    'Alexandre Kawano (MS16)': ['HJ', 1],
    'Arturo Cordero (MS03)': ['GR', 1],
    'Celso Furukawa (ES03)': ['DN', 1],
    'Delson Torikai (MS12)': ['HL', 1],
    'Diolino (MS30)': ['HE', 1],
    'Edison Gonçalves (TS27)': ['HZ', 1],
    'Edson Gomes (MS17)': ['GL', 1],
    'Eduardo Tannuri (MS23)': ['GI', 1],
    'Eduardo Cabral (MS22)': ['HH', 1],
    'Emilio Silva (MS06)': ['HN', 1],
    'Ettore de Barros (MS20)': ['HH', 1],
    'Fabio Cozman (MS08)': ['HM', 1],
    'Fabricio Junqueira (MS13)': ['GN', 1],
    'Flavio Buiochi (MS10)': ['HM', 1],
    'Gilberto Martha (TS31)': ['HY', 1],
    'Gilmar Batalha (MS19)': ['GK', 1],
    'Izabel Machado (TS35)': ['HW', 1],
    'José Reinaldo Silva (MS26)': ['HF', 1],
    'Julio Cezar Adamowski (ES12)': ['FD', 1],
    'Jun Okamoto (ES05)': ['DO', 1],
    'Larissa Driemeier (MS11)': ['GP', 1],
    'Lucas Moscato (ES24)': ['FJ', 1],
    'Luiz Eduardo Lopes (MS21)': ['GJ', 1],
    'Marcilio Alves (MS11)': ['GP', 1],
    'Marcos Tsuzuki (MS18)': ['HI', 1],
    'Marcos Barreto (ES09)': ['DQ', 1],
    'Newton Maruyama (MS28)': ['HF', 1],
    'Nicola Getschko (MS25)': ['GH', 1],
    'Oswaldo Horikawa (MS24)': ['HG', 1],
    'Pai Chi Nan (MS14)': ['HK', 1],
    'Paulo Miyagi (ES24)': ['FJ', 1],
    'Rafael Traldi Moura (MS11)': ['GP', 1],
    'Ricardo Cury Ibrahim (MS02)': ['HO', 1],
    'Rodolfo Molinari (MS01)': ['GS', 1],
    'Rodrigo Stoeterau (MS04)': ['HN', 1],
    'Tarcisio Coelho (MS15)': ['GM', 1],
    'Thiago de Castro (MS27)': ['GG', 1],
    'Agenor de Toledo Fleury (ES20)': ['FH', 1],
    'Alberto Hernandez Neto (TS21)': ['IC', 1],
    'Antonio Luis de Campos Mariani (ES06)': ['FA', 1],
    'Antonio Luiz Pacifico (ES04)': ['EZ', 1],
    'Arlindo Tribess (TS19)': ['ID', 1],
    'Bruno Souza Carmo (ES34)': ['FO', 1],
    'Clovis de Arruda Martins (ES21)': ['DW', 1],
    'Decio Crisol Donha (ES29)': ['EA', 1],
    'Demetrio Cornilios Zachariadis (ES17)': ['DU', 1],
    'Douglas Lauria (ES10)': ['FC', 1],
    'Edilson Hiroshi Tamai (ES25)': ['DY', 1],
    'Eitaro Yamane (TS17)': ['IG', 1],
    'Ernani Vitillo Volpe (TS13)': ['II', 1],
    'Fábio Saltara (NDF)': ['HT', 1],
    'Flávio Augusto Sanzogo Fiorelli (TS23)': ['IB', 1],
    'Flavio Celso Trigo (ES32)': ['FN', 1],
    'Flavius Portella Ribas Martins (ES13)': ['DS', 1],
    'Francisco José Profito (ES27)': ['DZ', 1],
    'Guenter Carlos Krieger Filho (TS15)': ['IH', 1],
    'Jayme Pinto Ortiz (ES02)': ['EY', 1],
    'Jorge Luis Baliño (NDF)': ['HT', 1],
    'José Robero Simões Moreira (ES30)': ['FM', 1],
    'Julio Romano Meneghini (NDF)': ['HT', 1],
    'Jurandi Itizo Yanagihara (TS29)': ['HY', 1],
    'Leandro Vieira da Silva Macedo (ES28)': ['FL', 1],
    'Linilson Rodrigues Padovese (ES23)': ['DX', 1],
    'Marcelo Augusto Leal Alves (ES40)': ['FR', 1],
    'Marcelo Massarani (ES28)': ['FL', 1],
    'Marcos de Mattos Pimenta (ES02)': ['EY', 1],
    'Marcos Tadeu Pereira (ES10)': ['FC', 1],
    'Mauricio Assumpção Trielli (ES10)': ['FC', 1],
    'Mauricio Silva Ferreira (TS33)': ['HX', 1],
    'Paulo Carlos Kaminski (ES40)': ['FR', 1],
    'Raúl Gonzáles Lima (ES27)': ['DZ', 1],
    'Roberto Martins de Souza (ES11)': ['DR', 1],
    'Roberto Ramos Junior (ES01)': ['DM', 1],
    'Roberto Spinola Barbosa (ES11)': ['DR', 1],
    'Ronaldo de Breyne Salvagni (ES36)': ['FP', 1],
    'Sadalla Domingos (ES10)': ['FC', 1],
    'Sergio Roberto Ceccato (ES10)': ['FC', 1],
    'Silvio de Oliveira Junior (TS16)': ['IU', 1],
    'Walter Jorge Augusto Ponge-Ferreira (ES36)': ['FP', 1],
    'Alexandre Nicolaos Simos (ES45)': ['EJ', 1],
    'Andre Bergsten Mendes (ES57)': ['ER', 1],
    'Bernardo Luis Rodrigues de Andrade (ES43)': ['EI', 1],
    'Claudio Mueller Sampaio Prado (ES43)': ['EI', 1],
    'Claudio Ruggieri (ES65)': ['EV', 1],
    'Diego Felipe Sarzosa Burgos (TPN)': ['DL', 0],
    'Gustavo Roque da Silva Assi (ES57)': ['ER', 1],
    'Hernani Luiz Brinati (TPN)': ['DL', 0],
    'Hélio Mitio Morishita (ES31)': ['EB', 1],
    'Jessé Rebello de Souza Junior (ES33)': ['EC', 1],
    'Kazuo Nishimoto (ES35)': ['ED', 1],
    'Marcelo Ramos Martins (ES55)': ['EO', 1],
    'Marco Antonio Brinati (ES47)': ['EK', 1],
    'Marcos Mendes de Oliveira Pinto (ES61)': ['ET', 1],
    'Toshilchi Tachibana (ES63)': ['EU', 1],
    'Centro de Engenharia Automotiva': ['DT', 1],
    'Grupo de Pesquisa em Refrigeração, Ar Condicionado e Conforto Termico': ['BB', 0],
    'Laboratório de Acústica e Meio Ambiente': ['CJ', 0],
    'Laboratório de Dinâmica e Controle': ['BO', 0],
    'Laboratório de Dinâmica e Simulação Veicular': ['BO', 0],
    'Laboratório de Engenharia Ambiental e Biomédica': ['CI', 0],
    'Laboratório de Engenharia Térmica e Ambiental': ['IQ', 1],
    'Laboratório de Estudos da Qualidade do Ar Interior': ['CK', 0],
    'Laboratório de Fenômenos de Superfícies': ['DA', 0],
    'Núcleo de Dinâmica e Fluidos': ['HT', 1],
    'Laboratório de Mecânica dos Fluidos': ['CB', 0],
    'Laboratório de Análise de Confiabilidade': ['GQ', 1],
    'Laboratório de Biomecatrônica': ['IP', 1],
    'Laboratório de Design': ['HE', 1],
    'Laboratório de Engenharia de Fabricação': ['DC', 0],
    'Laboratório de Fenômenos de Superfícies': ['DA', 0],
    'Laboratório de Geometria Computacional': ['HJ', 1],
    'Laboratório de Impactos em Estruturas': ['DF', 0],
    'Laboratório de Mecanismos, Máquinas e Robôs': ['GP', 1],
    'Laboratório de Otimização de Sistemas Multifísicos': ['HM', 1],
    'Laboratório de Percepção Avançada': ['HF', 1],
    'Laboratório de Robôs Sociáveis': ['GQ', 1],
    'Laboratório de Sensores e Atuadores': ['DC', 0],
    'Laboratório de Sistemas de Automação': ['DC', 0],
    'Laboratório de Sistemas Embarcados': ['HN', 1],
    'Laboratório de Sistemas Mecatrônicos de Precisão': ['HJ', 1],
    'Laboratório de Tomada de Decisão': ['HG', 1],
    'Laboratório de Ultrassom': ['DA', 0],
    'Laboratório de Veículos não Tripulados': ['HI', 1],
    'Laboratório de Análise, Avaliação e Gerenciamento de Riscos': ['N', 0],
    'Laboratório de Modelos': ['AY', 0],
    'Laboratório de Otimização e Projeto Integrado': ['M', 0],
    'Núcleo Avançado em Mecânica da Fratura e Integridade Estrutural': ['P', 0],
    'Tanque de Provas Numérico': ['DL', 0],
    'ThunderRatz': ['CR', 0],
    'Poli Milhagem': ['CN', 0],
    'Poli Náutico': ['AY', 0],
    'Keep Flying': ['CL', 0],
    'PET Mecânica': ['AW', 0],
    'PET Mecatrônica': ['GY', 1],
    'Baja': ['BJ', 0],
    'Jupiter': ['CI', 0],
    'Fórmula': ['BZ', 0],
    'Biblioteca': ['X', 0],
    'Auditório': ['V', 0],
    'Lanchonete': ['AH', 0],
    'Secretaria PMR Terreo': ['BA', 0],
    'Secretaria PMR Superior': ['HA', 1],
    'Secretaria PME': ['U', 0],
    'Secretaria PNV': ['O', 0],
    'CAM': ['AD', 0],
    'CEN': ['AF', 0],
    'Portaria': ['A', 0],
    'Biblioteca Lado Esq': ['Q', 0],
    'Biblioteca Lado Dir': ['Y', 0],
    'Escada Portaria': ['T', 0],
    'Passarela Térreo': ['AX', 0],
    'Secretaria Marinha': ['EW', 1],
    'Passarela Superior': ['GE', 1]
};



export const maps = {
    'A':	{'B': [2.0063,'A-B'], 'DJ': [3.6655,'A-DJ'], 'U': [1.935,'A-U']},
    'AA':	{'Esc2': [1,'AA-Esc2'], 'P': [2.0052,'P-AA'], 'Z': [1.9846,'Z-AA']},
    'AB':	{'AD': [3.5594,'AB-AD'], 'Z': [1.1103,'Z-AB']},
    'AC':	{'AD': [2.5009,'AC-AD'], 'N': [1.4889,'N-AC']},
    'AD':	{'AE': [1.1003,'AD-AE'], 'AC': [2.5009,'AC-AD'], 'AB': [3.5594,'AB-AD']},
    'AE':	{'AF': [1.3883,'AE-AF'], 'AD': [1.1003,'AD-AE']},
    'AF':	{'AG': [0.9145,'AF-AG'], 'AE': [1.3883,'AE-AF']},
    'AG':	{'AH': [1.6319,'AG-AH'], 'AF': [0.9145,'AF-AG']},
    'AH':	{'AI': [2.4636,'AH-AI'], 'AK': [2.4684,'AH-AK'], 'AG': [1.6319,'AG-AH']},
    'AI':	{'AJ': [1.0974,'AI-AJ'], 'AH': [2.4636,'AH-AI']},
    'AJ':	{'AL': [1.3711,'AJ-AL'], 'BB': [0.6616,'AJ-BB'], 'AI': [1.0974,'AI-AJ']},
    'AK':	{'AL': [2.4636,'AK-AL'], 'AN': [2.4854,'AK-AN'], 'AH': [2.4684,'AH-AK']},
    'AL':	{'AM': [2.4654,'AL-AM'], 'AJ': [1.3711,'AJ-AL'], 'AK': [2.4636,'AK-AL']},
    'AM':	{'AN': [2.4636,'AM-AN'], 'AR': [2.2531,'AM-AR'], 'AL': [2.4654,'AL-AM']},
    'AN':	{'AO': [3.7286,'AN-AO'], 'AK': [2.4854,'AK-AN'], 'AM': [2.4636,'AM-AN']},
    'AO':	{'AP': [2.4801,'AO-AP'], 'AU': [2.6546,'AO-AU'], 'AN': [3.7286,'AN-AO']},
    'AP':	{'AQ': [1.5068,'AP-AQ'], 'AT': [3.44,'AP-AT'], 'Y': [2.5548,'Y-AP'], 'AO': [2.4801,'AO-AP']},
    'AQ':	{'AP': [1.5068,'AP-AQ']},
    'AR':	{'AS': [1.6911,'AR-AS'], 'AM': [2.2531,'AM-AR']},
    'AS':	{'AW': [2.4391,'AS-AW'], 'AR': [1.6911,'AR-AS']},
    'AT':	{'AX': [2.686,'AT-AX'], 'AV': [2.4801,'AT-AV'], 'AP': [3.44,'AP-AT']},
    'AU':	{'AV': [0.7854,'AU-AV'], 'AW': [2.4636,'AU-AW'], 'AO': [2.6546,'AO-AU']},
    'AV':	{'AY': [3.6448,'AV-AY'], 'AT': [2.4801,'AT-AV'], 'AU': [0.7854,'AU-AV']},
    'AW':	{'AY': [3.4714,'AW-AY'], 'CG': [1.1504,'AW-CG'], 'AS': [2.4391,'AS-AW'], 'AU': [2.4636,'AU-AW']},
    'AX':	{'Esc11': [1,'AX-Esc11'], 'AY': [4.9437,'AX-AY'], 'W': [2.5548,'W-AX'], 'AT': [2.686,'AT-AX']},
    'AY':	{'AZ': [1.909,'AY-AZ'], 'AX': [4.9437,'AX-AY'], 'AV': [3.6448,'AV-AY'], 'AW': [3.4714,'AW-AY']},
    'AZ':	{'BA': [1.8655,'AZ-BA'], 'CW': [0.5931,'AZ-CW'], 'AY': [1.909,'AY-AZ']},
    'B':	{'C': [5.5464,'B-C'], 'S': [2.3842,'B-S'], 'A': [2.0063,'A-B']},
    'BA':	{'Esc8': [1,'BA-Esc8'], 'AZ': [1.8655,'AZ-BA']},
    'BB':	{'Esc3': [1,'BB-Esc3'], 'BC': [0.6616,'BB-BC'], 'AJ': [0.6616,'AJ-BB']},
    'BC':	{'BD': [2.0805,'BC-BD'], 'BP': [2.2764,'BC-BP'], 'BB': [0.6616,'BB-BC']},
    'BD':	{'BE': [1.342,'BD-BE'], 'BC': [2.0805,'BC-BD']},
    'BE':	{'BF': [1.731,'BE-BF'], 'BD': [1.342,'BD-BE']},
    'BF':	{'BG': [1.5006,'BF-BG'], 'BE': [1.731,'BE-BF']},
    'BG':	{'Esc4': [1,'BG-Esc4'], 'BH': [1.1146,'BG-BH'], 'BF': [1.5006,'BF-BG']},
    'BH':	{'BI': [1.4866,'BH-BI'], 'BG': [1.1146,'BG-BH']},
    'BI':	{'BJ': [3.1195,'BI-BJ'], 'BH': [1.4866,'BH-BI']},
    'BJ':	{'BK': [2.0637,'BJ-BK'], 'BI': [3.1195,'BI-BJ']},
    'BK':	{'Esc5': [1,'BK-Esc5'], 'BL': [2.2265,'BK-BL'], 'BJ': [2.0637,'BJ-BK']},
    'BL':	{'BM': [2.3652,'BL-BM'], 'BK': [2.2265,'BK-BL']},
    'BM':	{'BN': [0.6843,'BM-BN'], 'BL': [2.3652,'BL-BM']},
    'BN':	{'BO': [1.2,'BN-BO'], 'BQ': [3.6167,'BN-BQ'], 'BM': [0.6843,'BM-BN']},
    'BO':	{'BP': [1.5417,'BO-BP'], 'BN': [1.2,'BN-BO']},
    'BP':	{'BO': [1.5417,'BO-BP'], 'BC': [2.2764,'BC-BP']},
    'BQ':	{'BR': [1.9515,'BQ-BR'], 'IW': [1.318,'BQ-IW'], 'BN': [3.6167,'BN-BQ']},
    'BR':	{'BS': [2.4028,'BR-BS'], 'BQ': [1.9515,'BQ-BR']},
    'BS':	{'BT': [0.5777,'BS-BT'], 'BR': [2.4028,'BR-BS']},
    'BT':	{'BS': [0.5777,'BS-BT']},
    'BU':	{'IW': [1.5446,'BU-IW'], 'BV': [0.717,'BU-BV']},
    'BV':	{'BW': [0.8405,'BV-BW'], 'BU': [0.717,'BU-BV']},
    'BW':	{'BX': [0.5156,'BW-BX'], 'BV': [0.8405,'BV-BW']},
    'BX':	{'BY': [1.4576,'BX-BY'], 'BW': [0.5156,'BW-BX']},
    'BY':	{'BZ': [0.3562,'BY-BZ'], 'BX': [1.4576,'BX-BY']},
    'BZ':	{'BY': [0.3562,'BY-BZ']},
    'C':	{'D': [3.2463,'C-D'], 'B': [5.5464,'B-C']},
    'CA':	{'IW': [4.2793,'CA-IW'], 'CB': [1.2224,'CA-CB']},
    'CB':	{'CC': [2.9075,'CB-CC'], 'CH': [2.6574,'CB-CH'], 'CA': [1.2224,'CA-CB']},
    'CC':	{'CD': [0.4355,'CC-CD'], 'CB': [2.9075,'CB-CC']},
    'CD':	{'CE': [0.5634,'CD-CE'], 'CC': [0.4355,'CC-CD']},
    'CE':	{'CF': [0.6858,'CE-CF'], 'CD': [0.5634,'CD-CE']},
    'CF':	{'CG': [3.0391,'CF-CG'], 'CE': [0.6858,'CE-CF']},
    'CG':	{'Esc6': [1,'CG-Esc6'], 'AW': [1.1504,'AW-CG'], 'CF': [3.0391,'CF-CG']},
    'CH':	{'CI': [2.0039,'CH-CI'], 'CK': [1.3426,'CH-CK'], 'CB': [2.6574,'CB-CH']},
    'CI':	{'CJ': [1.5639,'CI-CJ'], 'CH': [2.0039,'CH-CI']},
    'CJ':	{'CI': [1.5639,'CI-CJ']},
    'CK':	{'CL': [0.7976,'CK-CL'], 'CH': [1.3426,'CH-CK']},
    'CL':	{'CQ': [1.652,'CL-CQ'], 'CM': [1.1653,'CL-CM'], 'CK': [0.7976,'CK-CL']},
    'CM':	{'CN': [3.0243,'CM-CN'], 'CL': [1.1653,'CL-CM']},
    'CN':	{'Esc7': [1,'CN-Esc7'], 'CO': [1.7041,'CN-CO'], 'CM': [3.0243,'CM-CN']},
    'CO':	{'CP': [2.5433,'CO-CP'], 'CN': [1.7041,'CN-CO']},
    'CP':	{'DA': [1.2155,'CP-DA'], 'CS': [3.2983,'CP-CS'], 'CO': [2.5433,'CO-CP']},
    'CQ':	{'CR': [1.1472,'CQ-CR'], 'CS': [1.7041,'CQ-CS'], 'CL': [1.652,'CL-CQ']},
    'CR':	{'CQ': [1.1472,'CQ-CR']},
    'CS':	{'CT': [1.9497,'CS-CT'], 'CP': [3.2983,'CP-CS'], 'CQ': [1.7041,'CQ-CS']},
    'CT':	{'CU': [1.201,'CT-CU'], 'CS': [1.9497,'CS-CT']},
    'CU':	{'CV': [1.529,'CU-CV'], 'CT': [1.201,'CT-CU']},
    'CV':	{'CW': [0.7343,'CV-CW'], 'CX': [2.0962,'CV-CX'], 'CU': [1.529,'CU-CV']},
    'CW':	{'AZ': [0.5931,'AZ-CW'], 'CV': [0.7343,'CV-CW']},
    'CX':	{'CY': [1.4972,'CX-CY'], 'CV': [2.0962,'CV-CX']},
    'CY':	{'CZ': [1.0985,'CY-CZ'], 'CX': [1.4972,'CX-CY']},
    'CZ':	{'DC': [2.0419,'CZ-DC'], 'CY': [1.0985,'CY-CZ']},
    'D':	{'E': [2.275,'D-E'], 'C': [3.2463,'C-D']},
    'DA':	{'DB': [1.2313,'DA-DB'], 'CP': [1.2155,'CP-DA']},
    'DB':	{'DG': [1.6912,'DB-DG'], 'Esc9': [1,'DB-Esc9'], 'DA': [1.2313,'DA-DB']},
    'DC':	{'DE': [1.7145,'DC-DE'], 'CZ': [2.0419,'CZ-DC']},
    'DE':	{'DF': [2.9543,'DE-DF'], 'DC': [1.7145,'DC-DE']},
    'DF':	{'DG': [0.7136,'DF-DG'], 'DE': [2.9543,'DE-DF']},
    'DG':	{'DH': [1.6052,'DG-DH'], 'DF': [0.7136,'DF-DG'], 'DB': [1.6912,'DB-DG']},
    'DH':	{'DI': [0.8757,'DH-DI'], 'DG': [1.6052,'DG-DH']},
    'DI':	{'Esc10': [1,'DI-Esc10'], 'DH': [0.8757,'DH-DI']},
    'DJ':	{'DK': [20.6689,'DJ-DK'], 'A': [3.6655,'A-DJ']},
    'DK':	{'DJ': [20.6689,'DJ-DK']},
    'DM':	{'DN': [0.6508,'DM-DN']},
    'DN':	{'DO': [0.7218,'DN-DO'], 'DM': [0.6508,'DM-DN']},
    'DO':	{'DP': [0.5476,'DO-DP'], 'DN': [0.7218,'DN-DO']},
    'DP':	{'DQ': [0.5915,'DP-DQ'], 'DO': [0.5476,'DO-DP']},
    'DQ':	{'DR': [0.475,'DQ-DR'], 'DP': [0.5915,'DP-DQ']},
    'DR':	{'DS': [0.3253,'DR-DS'], 'DQ': [0.475,'DQ-DR']},
    'DS':	{'DT': [1.3598,'DS-DT'], 'FE': [2.0031,'DS-FE'], 'DR': [0.3253,'DR-DS']},
    'DT':	{'DU': [0.4009,'DT-DU'], 'DS': [1.3598,'DS-DT']},
    'DU':	{'DV': [0.9768,'DU-DV'], 'EX': [0.988,'DU-EX'], 'DT': [0.4009,'DT-DU']},
    'DV':	{'DW': [0.7084,'DV-DW'], 'DU': [0.9768,'DU-DV']},
    'DW':	{'DX': [0.6781,'DW-DX'], 'DV': [0.7084,'DV-DW']},
    'DX':	{'DY': [0.6338,'DX-DY'], 'DW': [0.6781,'DW-DX']},
    'DY':	{'DZ': [0.6454,'DY-DZ'], 'DX': [0.6338,'DX-DY']},
    'DZ':	{'EA': [0.6623,'DZ-EA'], 'FL': [2.0031,'DZ-FL'], 'DY': [0.6454,'DY-DZ']},
    'E':	{'F': [2.3975,'E-F'], 'D': [2.275,'D-E']},
    'EA':	{'EB': [0.6021,'EA-EB'], 'DZ': [0.6623,'DZ-EA']},
    'EB':	{'EC': [0.7303,'EB-EC'], 'EA': [0.6021,'EA-EB']},
    'EC':	{'ED': [0.6962,'EC-ED'], 'EB': [0.7303,'EB-EC']},
    'ED':	{'EE': [0.6959,'ED-EE'], 'EC': [0.6962,'EC-ED']},
    'EE':	{'EF': [0.716,'EE-EF'], 'ED': [0.6959,'ED-EE']},
    'EF':	{'EH': [0.6538,'EF-EH'], 'EE': [0.716,'EE-EF']},
    'EH':	{'EI': [0.616,'EH-EI'], 'EF': [0.6538,'EF-EH']},
    'EI':	{'EJ': [0.6958,'EI-EJ'], 'EH': [0.616,'EH-EI']},
    'EJ':	{'EK': [0.7194,'EJ-EK'], 'EI': [0.6958,'EI-EJ']},
    'EK':	{'EL': [0.7038,'EK-EL'], 'EJ': [0.7194,'EJ-EK']},
    'EL':	{'EM': [0.6545,'EL-EM'], 'EK': [0.7038,'EK-EL']},
    'EM':	{'EN': [0.33,'EM-EN'], 'EL': [0.6545,'EL-EM']},
    'EN':	{'EO': [0.5319,'EN-EO'], 'FX': [2.0031,'EN-FX'], 'EM': [0.33,'EM-EN']},
    'EO':	{'EP': [0.8151,'EO-EP'], 'EN': [0.5319,'EN-EO']},
    'EP':	{'EQ': [0.3147,'EP-EQ'], 'EW': [0.9883,'EP-EW'], 'EO': [0.8151,'EO-EP']},
    'EQ':	{'ER': [0.7237,'EQ-ER'], 'EP': [0.3147,'EP-EQ']},
    'ER':	{'ES': [0.6653,'ER-ES'], 'EQ': [0.7237,'EQ-ER']},
    'ES':	{'ET': [0.6864,'ES-ET'], 'ER': [0.6653,'ER-ES']},
    'ET':	{'EU': [0.5212,'ET-EU'], 'ES': [0.6864,'ES-ET']},
    'EU':	{'EV': [0.8582,'EU-EV'], 'ET': [0.5212,'ET-EU']},
    'EV':	{'EU': [0.8582,'EU-EV']},
    'EW':	{'Esc2': [1,'EW-Esc2'], 'FY': [1.0147,'EW-FY'], 'EP': [0.9883,'EP-EW']},
    'EX':	{'FG': [1.0151,'EX-FG'], 'Esc1': [1,'EX-Esc1'], 'DU': [0.988,'DU-EX']},
    'EY':	{'EZ': [0.676,'EY-EZ']},
    'EZ':	{'FA': [0.6747,'EZ-FA'], 'EY': [0.676,'EY-EZ']},
    'Esc1':	{'T': [1,'T-Esc1'], 'EX': [1,'EX-Esc1']},
    'Esc10':	{'DI': [1,'DI-Esc10'], 'GU': [1,'GU-Esc10']},
    'Esc11':	{'AX': [1,'AX-Esc11'], 'GE': [1,'GE-Esc11']},
    'Esc2':	{'AA': [1,'AA-Esc2'], 'EW': [1,'EW-Esc2']},
    'Esc3':	{'BB': [1,'BB-Esc3'], 'IF': [1,'IF-Esc3']},
    'Esc4':	{'BG': [1,'BG-Esc4'], 'IR': [1,'IR-Esc4']},
    'Esc5':	{'BK': [1,'BK-Esc5'], 'IN': [1,'IN-Esc5']},
    'Esc6':	{'CG': [1,'CG-Esc6'], 'HS': [1,'HS-Esc6']},
    'Esc7':	{'CN': [1,'CN-Esc7'], 'HP': [1,'HP-Esc7']},
    'Esc8':	{'BA': [1,'BA-Esc8'], 'HB': [1,'HB-Esc8']},
    'Esc9':	{'DB': [1,'DB-Esc9'], 'GV': [1,'GV-Esc9']},
    'F':	{'G': [2.3655,'F-G'], 'E': [2.3975,'E-F']},
    'FA':	{'FB': [0.6521,'FA-FB'], 'EZ': [0.6747,'EZ-FA']},
    'FB':	{'FC': [0.5568,'FB-FC'], 'FA': [0.6521,'FA-FB']},
    'FC':	{'FD': [0.6235,'FC-FD'], 'FB': [0.5568,'FB-FC']},
    'FD':	{'FE': [0.5258,'FD-FE'], 'FC': [0.6235,'FC-FD']},
    'FE':	{'FF': [0.9197,'FE-FF'], 'DS': [2.0031,'DS-FE'], 'FD': [0.5258,'FD-FE']},
    'FF':	{'FG': [0.8411,'FF-FG'], 'FE': [0.9197,'FE-FF']},
    'FG':	{'FH': [1.0479,'FG-FH'], 'EX': [1.0151,'EX-FG'], 'FF': [0.8411,'FF-FG']},
    'FH':	{'FI': [0.8377,'FH-FI'], 'FG': [1.0479,'FG-FH']},
    'FI':	{'FJ': [0.6132,'FI-FJ'], 'FH': [0.8377,'FH-FI']},
    'FJ':	{'FK': [0.6498,'FJ-FK'], 'FI': [0.6132,'FI-FJ']},
    'FK':	{'FL': [0.4916,'FK-FL'], 'FJ': [0.6498,'FJ-FK']},
    'FL':	{'FM': [0.8339,'FL-FM'], 'GE': [3.5333,'FL-GE'], 'DZ': [2.0031,'DZ-FL'], 'FK': [0.4916,'FK-FL']},
    'FM':	{'FN': [0.7033,'FM-FN'], 'FL': [0.8339,'FL-FM']},
    'FN':	{'FO': [0.6635,'FN-FO'], 'FM': [0.7033,'FM-FN']},
    'FO':	{'FP': [0.7116,'FO-FP'], 'FN': [0.6635,'FN-FO']},
    'FP':	{'FQ': [0.7006,'FP-FQ'], 'FO': [0.7116,'FO-FP']},
    'FQ':	{'FR': [0.6327,'FQ-FR'], 'FP': [0.7006,'FP-FQ']},
    'FR':	{'FS': [0.6806,'FR-FS'], 'FQ': [0.6327,'FQ-FR']},
    'FS':	{'FT': [0.6736,'FS-FT'], 'FR': [0.6806,'FR-FS']},
    'FT':	{'FU': [0.7124,'FT-FU'], 'FS': [0.6736,'FS-FT']},
    'FU':	{'FV': [0.6514,'FU-FV'], 'FT': [0.7124,'FT-FU']},
    'FV':	{'FW': [0.6549,'FV-FW'], 'FU': [0.6514,'FU-FV']},
    'FW':	{'FX': [0.8599,'FW-FX'], 'FV': [0.6549,'FV-FW']},
    'FX':	{'FY': [1.3466,'FX-FY'], 'EN': [2.0031,'EN-FX'], 'FW': [0.8599,'FW-FX']},
    'FY':	{'FZ': [0.7074,'FY-FZ'], 'EW': [1.0147,'EW-FY'], 'FX': [1.3466,'FX-FY']},
    'FZ':	{'GA': [0.5834,'FZ-GA'], 'FY': [0.7074,'FY-FZ']},
    'G':	{'H': [2.3504,'G-H'], 'F': [2.3655,'F-G']},
    'GA':	{'GB': [0.663,'GA-GB'], 'FZ': [0.5834,'FZ-GA']},
    'GB':	{'GC': [0.7643,'GB-GC'], 'GA': [0.663,'GA-GB']},
    'GC':	{'GD': [1.4111,'GC-GD'], 'GB': [0.7643,'GB-GC']},
    'GD':	{'GC': [1.4111,'GC-GD']},
    'GE':	{'Esc11': [1,'GE-Esc11'], 'HC': [4.9693,'GE-HC'], 'FL': [3.5333,'FL-GE']},
    'GF':	{'GG': [0.7588,'GF-GG']},
    'GG':	{'GH': [0.4073,'GG-GH'], 'GY': [1.5153,'GG-GY'], 'GF': [0.7588,'GF-GG']},
    'GH':	{'GI': [0.5583,'GH-GI'], 'GG': [0.4073,'GG-GH']},
    'GI':	{'GJ': [0.5516,'GI-GJ'], 'GH': [0.5583,'GH-GI']},
    'GJ':	{'GK': [0.5604,'GJ-GK'], 'GI': [0.5516,'GI-GJ']},
    'GK':	{'GL': [0.6648,'GK-GL'], 'GJ': [0.5604,'GJ-GK']},
    'GL':	{'GM': [0.6066,'GL-GM'], 'GK': [0.6648,'GK-GL']},
    'GM':	{'GN': [0.6333,'GM-GN'], 'GL': [0.6066,'GL-GM']},
    'GN':	{'GO': [0.6282,'GN-GO'], 'GM': [0.6333,'GM-GN']},
    'GO':	{'GP': [1.7635,'GO-GP'], 'GN': [0.6282,'GN-GO']},
    'GP':	{'GQ': [1.4696,'GP-GQ'], 'GO': [1.7635,'GO-GP']},
    'GQ':	{'GR': [1.681,'GQ-GR'], 'GP': [1.4696,'GP-GQ']},
    'GR':	{'GS': [1.5096,'GR-GS'], 'GQ': [1.681,'GQ-GR']},
    'GS':	{'GT': [1.3716,'GS-GT'], 'GR': [1.5096,'GR-GS']},
    'GT':	{'GU': [0.3251,'GT-GU'], 'GS': [1.3716,'GS-GT']},
    'GU':	{'Esc10': [1,'GU-Esc10'], 'GT': [0.3251,'GT-GU']},
    'GV':	{'Esc9': [1,'GV-Esc9'], 'GW': [1.0145,'GV-GW']},
    'GW':	{'GX': [4.4964,'GW-GX'], 'GV': [1.0145,'GV-GW']},
    'GX':	{'GW': [4.4964,'GW-GX']},
    'GY':	{'GZ': [0.7904,'GY-GZ'], 'GG': [1.5153,'GG-GY']},
    'GZ':	{'HA': [0.6196,'GZ-HA'], 'GY': [0.7904,'GY-GZ']},
    'H':	{'I': [2.5212,'H-I'], 'G': [2.3504,'G-H']},
    'HA':	{'HB': [1.8206,'HA-HB'], 'GZ': [0.6196,'GZ-HA']},
    'HB':	{'Esc8': [1,'HB-Esc8'], 'HC': [2.4809,'HB-HC'], 'HA': [1.8206,'HA-HB']},
    'HC':	{'HD': [0.5412,'HC-HD'], 'HQ': [1.9409,'HC-HQ'], 'GE': [4.9693,'GE-HC'], 'HB': [2.4809,'HB-HC']},
    'HD':	{'HE': [1.2768,'HD-HE'], 'HC': [0.5412,'HC-HD']},
    'HE':	{'HF': [0.8938,'HE-HF'], 'HD': [1.2768,'HD-HE']},
    'HF':	{'HG': [1.1345,'HF-HG'], 'HE': [0.8938,'HE-HF']},
    'HG':	{'HH': [1.1908,'HG-HH'], 'HF': [1.1345,'HF-HG']},
    'HH':	{'HI': [0.9229,'HH-HI'], 'HG': [1.1908,'HG-HH']},
    'HI':	{'HJ': [0.6311,'HI-HJ'], 'HH': [0.9229,'HH-HI']},
    'HJ':	{'HK': [0.9498,'HJ-HK'], 'HI': [0.6311,'HI-HJ']},
    'HK':	{'HL': [1.0086,'HK-HL'], 'HJ': [0.9498,'HJ-HK']},
    'HL':	{'HM': [0.6412,'HL-HM'], 'HK': [1.0086,'HK-HL']},
    'HM':	{'HN': [1.0287,'HM-HN'], 'HL': [0.6412,'HL-HM']},
    'HN':	{'HO': [0.82,'HN-HO'], 'HM': [1.0287,'HM-HN']},
    'HO':	{'HP': [0.7008,'HO-HP'], 'HN': [0.82,'HN-HO']},
    'HP':	{'Esc7': [1,'HP-Esc7'], 'HO': [0.7008,'HO-HP']},
    'HQ':	{'HR': [1.4627,'HQ-HR'], 'HC': [1.9409,'HC-HQ']},
    'HR':	{'HS': [1.2756,'HR-HS'], 'HU': [1.6061,'HR-HU'], 'HQ': [1.4627,'HQ-HR']},
    'HS':	{'HT': [4.816,'HS-HT'], 'Esc6': [1,'HS-Esc6'], 'HR': [1.2756,'HR-HS']},
    'HT':	{'HS': [4.816,'HS-HT']},
    'HU':	{'HV': [0.7678,'HU-HV'], 'HR': [1.6061,'HR-HU']},
    'HV':	{'HW': [0.7698,'HV-HW'], 'HU': [0.7678,'HU-HV']},
    'HW':	{'HX': [0.5952,'HW-HX'], 'HV': [0.7698,'HV-HW']},
    'HX':	{'HY': [0.7797,'HX-HY'], 'HW': [0.5952,'HW-HX']},
    'HY':	{'HZ': [1.1041,'HY-HZ'], 'HX': [0.7797,'HX-HY']},
    'HZ':	{'IA': [0.5773,'HZ-IA'], 'HY': [1.1041,'HY-HZ']},
    'I':	{'J': [1.166,'I-J'], 'H': [2.5212,'H-I']},
    'IA':	{'IB': [0.5892,'IA-IB'], 'HZ': [0.5773,'HZ-IA']},
    'IB':	{'IC': [0.6692,'IB-IC'], 'IA': [0.5892,'IA-IB']},
    'IC':	{'ID': [0.5416,'IC-ID'], 'IB': [0.6692,'IB-IC']},
    'ID':	{'IE': [1.8631,'ID-IE'], 'IC': [0.5416,'IC-ID']},
    'IE':	{'IF': [0.6252,'IE-IF'], 'ID': [1.8631,'ID-IE']},
    'IF':	{'IG': [0.4881,'IF-IG'], 'Esc3': [1,'IF-Esc3'], 'IE': [0.6252,'IE-IF'], 'IV': [2.5593,'IF-IV']},
    'IG':	{'IH': [0.9399,'IG-IH'], 'IF': [0.4881,'IF-IG']},
    'IH':	{'II': [1.5741,'IH-II'], 'IG': [0.9399,'IG-IH']},
    'II':	{'IJ': [1.5435,'II-IJ'], 'IH': [1.5741,'IH-II']},
    'IJ':	{'IK': [1.2018,'IJ-IK'], 'II': [1.5435,'II-IJ']},
    'IK':	{'IL': [2.1454,'IK-IL'], 'IJ': [1.2018,'IJ-IK']},
    'IL':	{'IM': [1.6305,'IL-IM'], 'IK': [2.1454,'IK-IL']},
    'IM':	{'IN': [1.7421,'IM-IN'], 'IL': [1.6305,'IL-IM']},
    'IN':	{'IO': [2.5593,'IN-IO'], 'Esc5': [1,'IN-Esc5'], 'IM': [1.7421,'IM-IN']},
    'IO':	{'IP': [2.1953,'IO-IP'], 'IN': [2.5593,'IN-IO']},
    'IP':	{'IQ': [2.363,'IP-IQ'], 'IO': [2.1953,'IO-IP']},
    'IQ':	{'IR': [1.4463,'IQ-IR'], 'IP': [2.363,'IP-IQ']},
    'IR':	{'IS': [0.4398,'IR-IS'], 'Esc4': [1,'IR-Esc4'], 'IQ': [1.4463,'IQ-IR']},
    'IS':	{'IT': [1.5928,'IS-IT'], 'IR': [0.4398,'IR-IS']},
    'IT':	{'IU': [1.6036,'IT-IU'], 'IS': [1.5928,'IS-IT']},
    'IU':	{'IV': [1.6248,'IU-IV'], 'IT': [1.6036,'IT-IU']},
    'IV':	{'IF': [2.5593,'IF-IV'], 'IU': [1.6248,'IU-IV']},
    'IW':	{'BQ': [1.318,'BQ-IW'], 'BU': [1.5446,'BU-IW'], 'CA': [4.2793,'CA-IW']},
    'J':	{'K': [1.432,'J-K'], 'I': [1.166,'I-J']},
    'K':	{'L': [2.85,'K-L'], 'J': [1.432,'J-K']},
    'L':	{'M': [3.3783,'L-M'], 'K': [2.85,'K-L']},
    'M':	{'N': [2.1681,'M-N'], 'L': [3.3783,'L-M']},
    'N':	{'O': [1.768,'N-O'], 'AC': [1.4889,'N-AC'], 'M': [2.1681,'M-N']},
    'O':	{'P': [2.9017,'O-P'], 'N': [1.768,'N-O']},
    'P':	{'Q': [1.3748,'P-Q'], 'AA': [2.0052,'P-AA'], 'O': [2.9017,'O-P']},
    'Q':	{'R': [8.764,'Q-R'], 'P': [1.3748,'P-Q']},
    'R':	{'S': [3.3706,'R-S'], 'X': [1.9365,'R-X'], 'Q': [8.764,'Q-R']},
    'S':	{'T': [1.9949,'S-T'], 'R': [3.3706,'R-S'], 'B': [2.3842,'B-S']},
    'T':	{'V': [1.9949,'T-V'], 'Esc1': [1,'T-Esc1'], 'S': [1.9949,'S-T']},
    'U':	{'V': [2.3842,'U-V'], 'A': [1.935,'A-U']},
    'V':	{'W': [3.3706,'V-W'], 'U': [2.3842,'U-V'], 'T': [1.9949,'T-V']},
    'W':	{'X': [2.0533,'W-X'], 'Y': [6.126,'W-Y'], 'AX': [2.5548,'W-AX'], 'V': [3.3706,'V-W']},
    'X':	{'R': [1.9365,'R-X'], 'W': [2.0533,'W-X']},
    'Y':	{'Z': [4.0127,'Y-Z'], 'AP': [2.5548,'Y-AP'], 'W': [6.126,'W-Y']},
    'Z':	{'AA': [1.9846,'Z-AA'], 'AB': [1.1103,'Z-AB'], 'Y': [4.0127,'Y-Z']}
  };

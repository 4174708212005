import styled from 'styled-components';
import Icon from '../../Components/Icon/icon';

export const Svg = styled(Icon)`
    display: flex;
    position: absolute;
    z-index: 1;
`

export const Svg_pin = styled(Icon)`
    display: flex;
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
`

export const Svg_man = styled(Icon)`
    display: flex;
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
`

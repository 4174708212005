// Dictionary including all options and its categories

export const places = [
    {key: 'A0', cat:'Salas'},
    {key: 'A1A', cat:'Salas'},
    {key: 'A2', cat:'Salas'},
    {key: 'A3', cat:'Salas'},
    {key: 'A4', cat:'Salas'},
    {key: 'A5', cat:'Salas'},
    {key: 'A6', cat:'Salas'},
    {key: 'A7', cat:'Salas'},
    {key: 'A8', cat:'Salas'},
    {key: 'A9', cat:'Salas'},
    {key: 'A10', cat:'Salas'},
    {key: 'A10A', cat:'Salas'},
    {key: 'A11', cat:'Salas'},
    {key: 'A12', cat:'Salas'},
    {key: 'A13', cat:'Salas'},
    {key: 'A14A', cat:'Salas'},
    {key: 'A14B', cat:'Salas'},
    {key: 'A15', cat:'Salas'},
    {key: 'A16', cat:'Salas'},
    {key: 'A17', cat:'Salas'},
    {key: 'A18', cat:'Salas'},
    {key: 'A19', cat:'Salas'},
    {key: 'ET1', cat:'Salas'},
    {key: 'ET2', cat:'Salas'},
    {key: 'ET3', cat:'Salas'},
    {key: 'ET4', cat:'Salas'},
    {key: 'ET5', cat:'Salas'},
    {key: 'ET6', cat:'Salas'},
    {key: 'ET7', cat:'Salas'},
    {key: 'ET8', cat:'Salas'},
    {key: 'ET9', cat:'Salas'},
    {key: 'ET10', cat:'Salas'},
    {key: 'ET11', cat:'Salas'},
    {key: 'ET12', cat:'Salas'},
    {key: 'ET13', cat:'Salas'},
    {key: 'TT01', cat:'Salas'},
    {key: 'TT02', cat:'Salas'},
    {key: 'TT03', cat:'Salas'},
    {key: 'TT04', cat:'Salas'},
    {key: 'TT05', cat:'Salas'},
    {key: 'TT06', cat:'Salas'},
    {key: 'TT07', cat:'Salas'},
    {key: 'TT08', cat:'Salas'},
    {key: 'TT09', cat:'Salas'},
    {key: 'TT10', cat:'Salas'},
    {key: 'TT11', cat:'Salas'},
    {key: 'TT12', cat:'Salas'},
    {key: 'TT13', cat:'Salas'},
    {key: 'TT14', cat:'Salas'},
    {key: 'TT15', cat:'Salas'},
    {key: 'TT16', cat:'Salas'},
    {key: 'TT22', cat:'Salas'},
    {key: 'TT28', cat:'Salas'},
    {key: 'TT32', cat:'Salas'},
    {key: 'TT34', cat:'Salas'},
    {key: 'TT36', cat:'Salas'},
    {key: 'HT01', cat:'Salas'},
    {key: 'HT02', cat:'Salas'},
    {key: 'HT04', cat:'Salas'},
    {key: 'MT01', cat:'Salas'},
    {key: 'MT03', cat:'Salas'},
    {key: 'MT04', cat:'Salas'},
    {key: 'MT05', cat:'Salas'},
    {key: 'MT07', cat:'Salas'},
    {key: 'MT08', cat:'Salas'},
    {key: 'MT09', cat:'Salas'},
    {key: 'MT11', cat:'Salas'},
    {key: 'MT15', cat:'Salas'},
    {key: 'MT16', cat:'Salas'},
    {key: 'MT19', cat:'Salas'},
    {key: 'MT20', cat:'Salas'},
    {key: 'MT21', cat:'Salas'},
    {key: 'MT22', cat:'Salas'},
    {key: 'MT23', cat:'Salas'},
    {key: 'MT24', cat:'Salas'},
    {key: 'MT25', cat:'Salas'},
    {key: 'MT26', cat:'Salas'},
    {key: 'MT28', cat:'Salas'},
    {key: 'MT30', cat:'Salas'},
    {key: 'BAT02', cat:'Salas'},
    {key: 'ES01', cat:'Salas'},
    {key: 'ES02', cat:'Salas'},
    {key: 'ES03', cat:'Salas'},
    {key: 'ES04', cat:'Salas'},
    {key: 'ES05', cat:'Salas'},
    {key: 'ES06', cat:'Salas'},
    {key: 'ES07', cat:'Salas'},
    {key: 'ES08', cat:'Salas'},
    {key: 'ES09', cat:'Salas'},
    {key: 'ES10', cat:'Salas'},
    {key: 'ES11', cat:'Salas'},
    {key: 'ES12', cat:'Salas'},
    {key: 'ES13', cat:'Salas'},
    {key: 'ES14', cat:'Salas'},
    {key: 'ES15', cat:'Salas'},
    {key: 'ES16', cat:'Salas'},
    {key: 'ES17', cat:'Salas'},
    {key: 'ES18', cat:'Salas'},
    {key: 'ES19', cat:'Salas'},
    {key: 'ES20', cat:'Salas'},
    {key: 'ES21', cat:'Salas'},
    {key: 'ES22', cat:'Salas'},
    {key: 'ES23', cat:'Salas'},
    {key: 'ES24', cat:'Salas'},
    {key: 'ES25', cat:'Salas'},
    {key: 'ES26', cat:'Salas'},
    {key: 'ES27', cat:'Salas'},
    {key: 'ES28', cat:'Salas'},
    {key: 'ES29', cat:'Salas'},
    {key: 'ES30', cat:'Salas'},
    {key: 'ES31', cat:'Salas'},
    {key: 'ES32', cat:'Salas'},
    {key: 'ES33', cat:'Salas'},
    {key: 'ES34', cat:'Salas'},
    {key: 'ES35', cat:'Salas'},
    {key: 'ES36', cat:'Salas'},
    {key: 'ES37', cat:'Salas'},
    {key: 'ES38', cat:'Salas'},
    {key: 'ES39', cat:'Salas'},
    {key: 'ES40', cat:'Salas'},
    {key: 'ES41', cat:'Salas'},
    {key: 'ES42', cat:'Salas'},
    {key: 'ES43', cat:'Salas'},
    {key: 'ES44', cat:'Salas'},
    {key: 'ES45', cat:'Salas'},
    {key: 'ES46', cat:'Salas'},
    {key: 'ES47', cat:'Salas'},
    {key: 'ES48', cat:'Salas'},
    {key: 'ES49', cat:'Salas'},
    {key: 'ES50', cat:'Salas'},
    {key: 'ES51', cat:'Salas'},
    {key: 'ES52', cat:'Salas'},
    {key: 'ES53', cat:'Salas'},
    {key: 'ES54', cat:'Salas'},
    {key: 'ES55', cat:'Salas'},
    {key: 'ES56', cat:'Salas'},
    {key: 'ES57', cat:'Salas'},
    {key: 'ES58', cat:'Salas'},
    {key: 'ES59', cat:'Salas'},
    {key: 'ES60', cat:'Salas'},
    {key: 'ES61', cat:'Salas'},
    {key: 'ES62', cat:'Salas'},
    {key: 'ES63', cat:'Salas'},
    {key: 'ES64', cat:'Salas'},
    {key: 'ES65', cat:'Salas'},
    {key: 'ES66', cat:'Salas'},
    {key: 'ES67', cat:'Salas'},
    {key: 'ES68', cat:'Salas'},
    {key: 'ES69', cat:'Salas'},
    {key: 'ES70', cat:'Salas'},
    {key: 'ES71', cat:'Salas'},
    {key: 'ES72', cat:'Salas'},
    {key: 'MC01', cat:'Salas'},
    {key: 'MC02', cat:'Salas'},
    {key: 'MC03', cat:'Salas'},
    {key: 'MC04', cat:'Salas'},
    {key: 'MC05', cat:'Salas'},
    {key: 'MS01', cat:'Salas'},
    {key: 'MS02', cat:'Salas'},
    {key: 'MS03', cat:'Salas'},
    {key: 'MS04', cat:'Salas'},
    {key: 'MS05', cat:'Salas'},
    {key: 'MS06', cat:'Salas'},
    {key: 'MS07', cat:'Salas'},
    {key: 'MS08', cat:'Salas'},
    {key: 'MS09', cat:'Salas'},
    {key: 'MS10', cat:'Salas'},
    {key: 'MS11', cat:'Salas'},
    {key: 'MS12', cat:'Salas'},
    {key: 'MS13', cat:'Salas'},
    {key: 'MS14', cat:'Salas'},
    {key: 'MS15', cat:'Salas'},
    {key: 'MS16', cat:'Salas'},
    {key: 'MS17', cat:'Salas'},
    {key: 'MS18', cat:'Salas'},
    {key: 'MS19', cat:'Salas'},
    {key: 'MS20', cat:'Salas'},
    {key: 'MS21', cat:'Salas'},
    {key: 'MS22', cat:'Salas'},
    {key: 'MS23', cat:'Salas'},
    {key: 'MS24', cat:'Salas'},
    {key: 'MS25', cat:'Salas'},
    {key: 'MS26', cat:'Salas'},
    {key: 'MS27', cat:'Salas'},
    {key: 'MS28', cat:'Salas'},
    {key: 'MS29', cat:'Salas'},
    {key: 'MS30', cat:'Salas'},
    {key: 'MS31', cat:'Salas'},
    {key: 'MS32', cat:'Salas'},
    {key: 'MS34', cat:'Salas'},
    {key: 'MZ01', cat:'Salas'},
    {key: 'MZ02', cat:'Salas'},
    {key: 'MZ04', cat:'Salas'},
    {key: 'MZ06', cat:'Salas'},
    {key: 'MZ08', cat:'Salas'},
    {key: 'MZ10', cat:'Salas'},
    {key: 'MZ12', cat:'Salas'},
    {key: 'MZ14', cat:'Salas'},
    {key: 'MZ16', cat:'Salas'},
    {key: 'MZ18', cat:'Salas'},
    {key: 'TS01', cat:'Salas'},
    {key: 'TS02', cat:'Salas'},
    {key: 'TS03', cat:'Salas'},
    {key: 'TS04', cat:'Salas'},
    {key: 'TS05', cat:'Salas'},
    {key: 'TS06', cat:'Salas'},
    {key: 'TS07', cat:'Salas'},
    {key: 'TS08', cat:'Salas'},
    {key: 'TS09', cat:'Salas'},
    {key: 'TS10', cat:'Salas'},
    {key: 'TS11', cat:'Salas'},
    {key: 'TS12', cat:'Salas'},
    {key: 'TS13', cat:'Salas'},
    {key: 'TS14', cat:'Salas'},
    {key: 'TS15', cat:'Salas'},
    {key: 'TS16', cat:'Salas'},
    {key: 'TS17', cat:'Salas'},
    {key: 'TS18', cat:'Salas'},
    {key: 'TS19', cat:'Salas'},
    {key: 'TS20', cat:'Salas'},
    {key: 'TS21', cat:'Salas'},
    {key: 'TS23', cat:'Salas'},
    {key: 'TS25', cat:'Salas'},
    {key: 'TS27', cat:'Salas'},
    {key: 'TS29', cat:'Salas'},
    {key: 'TS31', cat:'Salas'},
    {key: 'TS33', cat:'Salas'},
    {key: 'TS35', cat:'Salas'},
    {key: 'TS37', cat:'Salas'},
    {key: 'TS39', cat:'Salas'},
    {key: 'TS41', cat:'Salas'},
    {key: 'NDF', cat:'Salas'},
    {key: 'TPN', cat:'Salas'},
    {key: 'TS43', cat:'Salas'},
    {key: 'Alexandre Kawano (MS16)', cat:'Sala de Professor'},
    {key: 'Arturo Cordero (MS03)', cat:'Sala de Professor'},
    {key: 'Celso Furukawa (ES03)', cat:'Sala de Professor'},
    {key: 'Delson Torikai (MS12)', cat:'Sala de Professor'},
    {key: 'Diolino (MS30)', cat:'Sala de Professor'},
    {key: 'Edison Gonçalves (TS27)', cat:'Sala de Professor'},
    {key: 'Edson Gomes (MS17)', cat:'Sala de Professor'},
    {key: 'Eduardo Tannuri (MS23)', cat:'Sala de Professor'},
    {key: 'Eduardo Cabral (MS22)', cat:'Sala de Professor'},
    {key: 'Emilio Silva (MS06)', cat:'Sala de Professor'},
    {key: 'Ettore de Barros (MS20)', cat:'Sala de Professor'},
    {key: 'Fabio Cozman (MS08)', cat:'Sala de Professor'},
    {key: 'Fabricio Junqueira (MS13)', cat:'Sala de Professor'},
    {key: 'Flavio Buiochi (MS10)', cat:'Sala de Professor'},
    {key: 'Gilberto Martha (TS31)', cat:'Sala de Professor'},
    {key: 'Gilmar Batalha (MS19)', cat:'Sala de Professor'},
    {key: 'Izabel Machado (TS35)', cat:'Sala de Professor'},
    {key: 'José Reinaldo Silva (MS26)', cat:'Sala de Professor'},
    {key: 'Julio Cezar Adamowski (ES12)', cat:'Sala de Professor'},
    {key: 'Jun Okamoto (ES05)', cat:'Sala de Professor'},
    {key: 'Larissa Driemeier (MS11)', cat:'Sala de Professor'},
    {key: 'Lucas Moscato (ES24)', cat:'Sala de Professor'},
    {key: 'Luiz Eduardo Lopes (MS21)', cat:'Sala de Professor'},
    {key: 'Marcilio Alves (MS11)', cat:'Sala de Professor'},
    {key: 'Marcos Tsuzuki (MS18)', cat:'Sala de Professor'},
    {key: 'Marcos Barreto (ES09)', cat:'Sala de Professor'},
    {key: 'Newton Maruyama (MS28)', cat:'Sala de Professor'},
    {key: 'Nicola Getschko (MS25)', cat:'Sala de Professor'},
    {key: 'Oswaldo Horikawa (MS24)', cat:'Sala de Professor'},
    {key: 'Pai Chi Nan (MS14)', cat:'Sala de Professor'},
    {key: 'Paulo Miyagi (ES24)', cat:'Sala de Professor'},
    {key: 'Rafael Traldi Moura (MS11)', cat:'Sala de Professor'},
    {key: 'Ricardo Cury Ibrahim (MS02)', cat:'Sala de Professor'},
    {key: 'Rodolfo Molinari (MS01)', cat:'Sala de Professor'},
    {key: 'Rodrigo Stoeterau (MS04)', cat:'Sala de Professor'},
    {key: 'Tarcisio Coelho (MS15)', cat:'Sala de Professor'},
    {key: 'Thiago de Castro (MS27)', cat:'Sala de Professor'},
    {key: 'Agenor de Toledo Fleury (ES20)', cat:'Sala de Professor'},
    {key: 'Alberto Hernandez Neto (TS21)', cat:'Sala de Professor'},
    {key: 'Antonio Luis de Campos Mariani (ES06)', cat:'Sala de Professor'},
    {key: 'Antonio Luiz Pacifico (ES04)', cat:'Sala de Professor'},
    {key: 'Arlindo Tribess (TS19)', cat:'Sala de Professor'},
    {key: 'Bruno Souza Carmo (ES34)', cat:'Sala de Professor'},
    {key: 'Clovis de Arruda Martins (ES21)', cat:'Sala de Professor'},
    {key: 'Decio Crisol Donha (ES29)', cat:'Sala de Professor'},
    {key: 'Demetrio Cornilios Zachariadis (ES17)', cat:'Sala de Professor'},
    {key: 'Douglas Lauria (ES10)', cat:'Sala de Professor'},
    {key: 'Edilson Hiroshi Tamai (ES25)', cat:'Sala de Professor'},
    {key: 'Eitaro Yamane (TS17)', cat:'Sala de Professor'},
    {key: 'Ernani Vitillo Volpe (TS13)', cat:'Sala de Professor'},
    {key: 'Fábio Saltara (NDF)', cat:'Sala de Professor'},
    {key: 'Flávio Augusto Sanzogo Fiorelli (TS23)', cat:'Sala de Professor'},
    {key: 'Flavio Celso Trigo (ES32)', cat:'Sala de Professor'},
    {key: 'Flavius Portella Ribas Martins (ES13)', cat:'Sala de Professor'},
    {key: 'Francisco José Profito (ES27)', cat:'Sala de Professor'},
    {key: 'Guenter Carlos Krieger Filho (TS15)', cat:'Sala de Professor'},
    {key: 'Jayme Pinto Ortiz (ES02)', cat:'Sala de Professor'},
    {key: 'Jorge Luis Baliño (NDF)', cat:'Sala de Professor'},
    {key: 'José Robero Simões Moreira (ES30)', cat:'Sala de Professor'},
    {key: 'Julio Romano Meneghini (NDF)', cat:'Sala de Professor'},
    {key: 'Jurandi Itizo Yanagihara (TS29)', cat:'Sala de Professor'},
    {key: 'Leandro Vieira da Silva Macedo (ES28)', cat:'Sala de Professor'},
    {key: 'Linilson Rodrigues Padovese (ES23)', cat:'Sala de Professor'},
    {key: 'Marcelo Augusto Leal Alves (ES40)', cat:'Sala de Professor'},
    {key: 'Marcelo Massarani (ES28)', cat:'Sala de Professor'},
    {key: 'Marcos de Mattos Pimenta (ES02)', cat:'Sala de Professor'},
    {key: 'Marcos Tadeu Pereira (ES10)', cat:'Sala de Professor'},
    {key: 'Mauricio Assumpção Trielli (ES10)', cat:'Sala de Professor'},
    {key: 'Mauricio Silva Ferreira (TS33)', cat:'Sala de Professor'},
    {key: 'Paulo Carlos Kaminski (ES40)', cat:'Sala de Professor'},
    {key: 'Raúl Gonzáles Lima (ES27)', cat:'Sala de Professor'},
    {key: 'Roberto Martins de Souza (ES11)', cat:'Sala de Professor'},
    {key: 'Roberto Ramos Junior (ES01)', cat:'Sala de Professor'},
    {key: 'Roberto Spinola Barbosa (ES11)', cat:'Sala de Professor'},
    {key: 'Ronaldo de Breyne Salvagni (ES36)', cat:'Sala de Professor'},
    {key: 'Sadalla Domingos (ES10)', cat:'Sala de Professor'},
    {key: 'Sergio Roberto Ceccato (ES10)', cat:'Sala de Professor'},
    {key: 'Silvio de Oliveira Junior (TS16)', cat:'Sala de Professor'},
    {key: 'Walter Jorge Augusto Ponge-Ferreira (ES36)', cat:'Sala de Professor'},
    {key: 'Alexandre Nicolaos Simos (ES45)', cat:'Sala de Professor'},
    {key: 'Andre Bergsten Mendes (ES57)', cat:'Sala de Professor'},
    {key: 'Bernardo Luis Rodrigues de Andrade (ES43)', cat:'Sala de Professor'},
    {key: 'Claudio Mueller Sampaio Prado (ES43)', cat:'Sala de Professor'},
    {key: 'Claudio Ruggieri (ES65)', cat:'Sala de Professor'},
    {key: 'Diego Felipe Sarzosa Burgos (TPN)', cat:'Sala de Professor'},
    {key: 'Gustavo Roque da Silva Assi (ES57)', cat:'Sala de Professor'},
    {key: 'Hernani Luiz Brinati (TPN)', cat:'Sala de Professor'},
    {key: 'Hélio Mitio Morishita (ES31)', cat:'Sala de Professor'},
    {key: 'Jessé Rebello de Souza Junior (ES33)', cat:'Sala de Professor'},
    {key: 'Kazuo Nishimoto (ES35)', cat:'Sala de Professor'},
    {key: 'Marcelo Ramos Martins (ES55)', cat:'Sala de Professor'},
    {key: 'Marco Antonio Brinati (ES47)', cat:'Sala de Professor'},
    {key: 'Marcos Mendes de Oliveira Pinto (ES61)', cat:'Sala de Professor'},
    {key: 'Toshilchi Tachibana (ES63)', cat:'Sala de Professor'},
    {key: 'Centro de Engenharia Automotiva', cat:'Laboratório'},
    {key: 'Grupo de Pesquisa em Refrigeração, Ar Condicionado e Conforto Termico', cat:'Laboratório'},
    {key: 'Laboratório de Acústica e Meio Ambiente', cat:'Laboratório'},
    {key: 'Laboratório de Dinâmica e Controle', cat:'Laboratório'},
    {key: 'Laboratório de Dinâmica e Simulação Veicular', cat:'Laboratório'},
    {key: 'Laboratório de Engenharia Ambiental e Biomédica', cat:'Laboratório'},
    {key: 'Laboratório de Engenharia Térmica e Ambiental', cat:'Laboratório'},
    {key: 'Laboratório de Estudos da Qualidade do Ar Interior', cat:'Laboratório'},
    {key: 'Laboratório de Fenômenos de Superfícies', cat:'Laboratório'},
    {key: 'Núcleo de Dinâmica e Fluidos', cat:'Laboratório'},
    {key: 'Laboratório de Mecânica dos Fluidos', cat:'Laboratório'},
    {key: 'Laboratório de Análise de Confiabilidade', cat:'Laboratório'},
    {key: 'Laboratório de Biomecatrônica', cat:'Laboratório'},
    {key: 'Laboratório de Design', cat:'Laboratório'},
    {key: 'Laboratório de Engenharia de Fabricação', cat:'Laboratório'},
    {key: 'Laboratório de Fenômenos de Superfícies', cat:'Laboratório'},
    {key: 'Laboratório de Geometria Computacional', cat:'Laboratório'},
    {key: 'Laboratório de Impactos em Estruturas', cat:'Laboratório'},
    {key: 'Laboratório de Mecanismos, Máquinas e Robôs', cat:'Laboratório'},
    {key: 'Laboratório de Otimização de Sistemas Multifísicos', cat:'Laboratório'},
    {key: 'Laboratório de Percepção Avançada', cat:'Laboratório'},
    {key: 'Laboratório de Robôs Sociáveis', cat:'Laboratório'},
    {key: 'Laboratório de Sensores e Atuadores', cat:'Laboratório'},
    {key: 'Laboratório de Sistemas de Automação', cat:'Laboratório'},
    {key: 'Laboratório de Sistemas Embarcados', cat:'Laboratório'},
    {key: 'Laboratório de Sistemas Mecatrônicos de Precisão', cat:'Laboratório'},
    {key: 'Laboratório de Tomada de Decisão', cat:'Laboratório'},
    {key: 'Laboratório de Ultrassom', cat:'Laboratório'},
    {key: 'Laboratório de Veículos não Tripulados', cat:'Laboratório'},
    {key: 'Laboratório de Análise, Avaliação e Gerenciamento de Riscos', cat:'Laboratório'},
    {key: 'Laboratório de Modelos', cat:'Laboratório'},
    {key: 'Laboratório de Otimização e Projeto Integrado', cat:'Laboratório'},
    {key: 'Núcleo Avançado em Mecânica da Fratura e Integridade Estrutural', cat:'Laboratório'},
    {key: 'Tanque de Provas Numérico', cat:'Laboratório'},
    {key: 'ThunderRatz', cat:'Grupo de Extensão'},
    {key: 'Poli Milhagem', cat:'Grupo de Extensão'},
    {key: 'Poli Náutico', cat:'Grupo de Extensão'},
    {key: 'Keep Flying', cat:'Grupo de Extensão'},
    {key: 'PET Mecânica', cat:'Grupo de Extensão'},
    {key: 'PET Mecatrônica', cat:'Grupo de Extensão'},
    {key: 'Baja', cat:'Grupo de Extensão'},
    {key: 'Jupiter', cat:'Grupo de Extensão'},
    {key: 'Fórmula', cat:'Grupo de Extensão'},
    {key: 'Biblioteca', cat:'Outros'},
    {key: 'Auditório', cat:'Outros'},
    {key: 'Lanchonete', cat:'Outros'},
    {key: 'Secretaria PMR Terreo', cat:'Outros'},
    {key: 'Secretaria PMR Superior', cat:'Outros'},
    {key: 'Secretaria PME', cat:'Outros'},
    {key: 'Secretaria PNV', cat:'Outros'},
    {key: 'CAM', cat:'Outros'},
    {key: 'CEN', cat:'Outros'},
    {key: 'Portaria', cat:'Outros'},
    {key: 'Biblioteca Lado Esq', cat:'Outros'},
    {key: 'Biblioteca Lado Dir', cat:'Outros'},
    {key: 'Escada Portaria', cat:'Outros'},
    {key: 'Passarela Térreo', cat:'Outros'},
    {key: 'Secretaria Marinha', cat:'Outros'},
    {key: 'Passarela Superior', cat:'Outros'}
];

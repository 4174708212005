// dictionary with coordinates of each node. Taken from the CAD used to draw the paths

export const coords = {
    'A' : [6.9184, 30.0497],
    'C' : [6.9184, 37.6025],
    'D' : [10.1647, 37.6025],
    'E' : [12.4397, 37.6025],
    'F' : [14.8372, 37.6025],
    'G' : [17.1621, 37.6025],
    'H' : [19.5125, 37.6025],
    'I' : [22.0337, 37.6025],
    'J' : [23.1997, 37.6025],
    'K' : [24.6317, 37.6025],
    'L' : [27.4818, 37.6025],
    'M' : [27.4818, 34.2241],
    'N' : [27.4818, 32.056],
    'O' : [25.7138, 32.056],
    'P' : [22.812, 32.056],
    'Q' : [21.4372, 32.056],
    'T' : [9.3026, 30.0611],
    'U' : [6.9184, 28.0662],
    'V' : [9.3026, 28.0662],
    'X' : [12.6732, 30.1195],
    'Y' : [18.7993, 28.0662],
    'AB' : [23.9224, 28.0662],
    'AC' : [27.4818, 30.5671],
    'AD' : [27.4818, 28.0662],
    'AE' : [27.4818, 26.9659],
    'AF' : [27.4818, 25.5776],
    'AG' : [27.4818, 24.6631],
    'AH' : [27.4818, 23.0312],
    'AW' : [16.1447, 20.5676],
    'AX' : [12.6732, 25.5113],
    'AY' : [12.6732, 20.5676],
    'BA' : [8.8988, 20.5676],
    'BB' : [26.3844, 19.906],
    'BC' : [26.3844, 19.2444],
    'BF' : [28.4649, 16.1715],
    'BH' : [28.4649, 13.5563],
    'BI' : [28.4649, 12.0696],
    'BJ' : [28.4649, 8.9502],
    'BK' : [26.2844, 8.9502],
    'BL' : [26.2844, 11.1767],
    'BM' : [26.2844, 13.5419],
    'BO' : [26.2844, 15.4263],
    'BP' : [26.2844, 16.968],
    'BQ' : [22.7676, 14.2263],
    'BT' : [22.19, 18.5805],
    'BV' : [22.0507, 11.3637],
    'BX' : [21.535, 10.5232],
    'BZ' : [21.1789, 9.0656],
    'CB' : [17.266, 12.9083],
    'CH' : [14.6086, 12.9083],
    'CI' : [14.6086, 10.9044],
    'CK' : [13.266, 12.9083],
    'CL' : [12.4684, 12.9083],
    'CM' : [12.4684, 11.743],
    'CN' : [12.4684, 8.7187],
    'CR' : [12.4684, 15.7075],
    'CT' : [10.7643, 16.5101],
    'CU' : [10.7643, 17.7111],
    'CV' : [10.7643, 19.2402],
    'CW' : [10.7643, 19.9744],
    'CX' : [8.6681, 19.2402],
    'CY' : [8.6681, 17.7429],
    'CZ' : [8.6681, 16.6444],
    'DA' : [9.5487, 11.262],
    'DC' : [6.6262, 16.6444],
    'DE' : [6.6262, 14.9299],
    'DF' : [6.6262, 11.9756],
    'DI' : [6.6262, 8.7811],
    'DK' : [3.2529, 9.3808],
    'DM' : [3.6645, 32.9045],
    'DN' : [4.3153, 32.9045],
    'DO' : [5.0371, 32.9045],
    'DP' : [5.5847, 32.9045],
    'DQ' : [6.1762, 32.9045],
    'DR' : [6.6513, 32.9045],
    'DS' : [6.9765, 32.9045],
    'DT' : [8.3363, 32.9045],
    'DU' : [8.7372, 32.9045],
    'DV' : [9.7141, 32.9045],
    'DW' : [10.4224, 32.9045],
    'DX' : [11.1006, 32.9045],
    'DY' : [11.7343, 32.9045],
    'DZ' : [12.3797, 32.9045],
    'EA' : [13.042, 32.9045],
    'EB' : [13.6441, 32.9045],
    'EC' : [14.3745, 32.9045],
    'ED' : [15.0707, 32.9045],
    'EE' : [15.7665, 32.9045],
    'EF' : [16.4825, 32.9045],
    'EH' : [17.1362, 32.9045],
    'EI' : [17.7522, 32.9045],
    'EJ' : [18.448, 32.9045],
    'EK' : [19.1674, 32.9045],
    'EL' : [19.8712, 32.9045],
    'EM' : [20.5257, 32.9045],
    'EN' : [20.8557, 32.9045],
    'EO' : [21.3875, 32.9045],
    'ER' : [23.2411, 32.9045],
    'ES' : [23.9064, 32.9045],
    'ET' : [24.5928, 32.9045],
    'EU' : [25.1141, 32.9045],
    'EV' : [25.9722, 32.9045],
    'EW' : [22.2027, 31.9162],
    'EX' : [8.7372, 31.9165],
    'EY' : [3.2676, 30.9014],
    'EZ' : [3.9436, 30.9014],
    'FA' : [4.6183, 30.9014],
    'FB' : [5.2705, 30.9014],
    'FC' : [5.8272, 30.9014],
    'FD' : [6.4507, 30.9014],
    'FE' : [6.9765, 30.9014],
    'FF' : [7.8962, 30.9014],
    'FG' : [8.7372, 30.9014],
    'FH' : [9.7852, 30.9014],
    'FI' : [10.6228, 30.9014],
    'FJ' : [11.236, 30.9014],
    'FK' : [11.8858, 30.9014],
    'FL' : [12.3774, 30.9014],
    'FM' : [13.2112, 30.9014],
    'FN' : [13.9145, 30.9014],
    'FO' : [14.578, 30.9014],
    'FP' : [15.2896, 30.9014],
    'FQ' : [15.9901, 30.9014],
    'FR' : [16.6228, 30.9014],
    'FS' : [17.3035, 30.9014],
    'FT' : [17.9771, 30.9014],
    'FU' : [18.6895, 30.9014],
    'FV' : [19.3409, 30.9014],
    'FW' : [19.9958, 30.9014],
    'FX' : [20.8557, 30.9014],
    'FZ' : [22.9096, 30.9014],
    'GA' : [23.4929, 30.9014],
    'GB' : [24.156, 30.9014],
    'GC' : [24.9203, 30.9014],
    'GD' : [26.3314, 30.9014],
    'GE' : [12.3774, 27.3681],
    'GG' : [5.746, 21.7793],
    'GH' : [5.746, 21.372],
    'GI' : [5.746, 20.8137],
    'GJ' : [5.746, 20.2621],
    'GK' : [5.746, 19.7018],
    'GL' : [5.746, 19.0369],
    'GM' : [5.746, 18.4303],
    'GN' : [5.746, 17.7971],
    'GP' : [5.746, 15.4053],
    'GQ' : [5.746, 13.9358],
    'GR' : [5.746, 12.2548],
    'GS' : [5.746, 10.7452],
    'GY' : [7.2612, 21.7793],
    'GZ' : [8.0517, 21.7793],
    'HA' : [8.0517, 22.3989],
    'HD' : [12.3532, 21.8577],
    'HE' : [12.3532, 20.5809],
    'HF' : [12.3532, 19.6872],
    'HG' : [12.3532, 18.5526],
    'HH' : [12.3532, 17.3618],
    'HI' : [12.3532, 16.4389],
    'HJ' : [12.3532, 15.8078],
    'HK' : [12.3532, 14.858],
    'HL' : [12.3532, 13.8494],
    'HM' : [12.3532, 13.2083],
    'HN' : [12.3532, 12.1796],
    'HO' : [12.3532, 11.3595],
    'HQ' : [14.2941, 22.3989],
    'HR' : [15.7567, 22.3989],
    'HT' : [15.7567, 16.3073],
    'HU' : [17.3628, 22.3989],
    'HV' : [18.1306, 22.3989],
    'HW' : [18.9004, 22.3989],
    'HX' : [19.4955, 22.3989],
    'HY' : [20.2752, 22.3989],
    'HZ' : [21.3793, 22.3989],
    'IA' : [21.9566, 22.3989],
    'IB' : [22.5459, 22.3989],
    'IC' : [23.215, 22.3989],
    'ID' : [23.7566, 22.3989],
    'IG' : [25.6197, 21.2856],
    'IH' : [25.6197, 20.3457],
    'II' : [25.6197, 18.7716],
    'IJ' : [25.6197, 17.228],
    'IK' : [25.6197, 16.0263],
    'IL' : [25.6197, 13.8808],
    'IM' : [25.6197, 12.2503],
    'IN' : [25.6197, 10.5082],
    'IP' : [28.179, 12.7035],
    'IQ' : [28.179, 15.0665],
    'IS' : [28.179, 16.9526],
    'IT' : [28.179, 18.5454],
    'IU' : [28.179, 20.1489],
    'IV' : [28.179, 21.7737]
};


